import { useState, useEffect } from "react";

import type { txnDatabyHashType } from "../utils/types";
import TransactionByHash from "./TransactionByHash";

const TransactionByHashPaginate = (props: {
  itemsPerPage: number;
  txnDataOutput: txnDatabyHashType[];
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
  addressesArr: string[];
  tokenDecimal: { [key: string]: number };
}) => {
  const { itemsPerPage, txnDataOutput, fetchingData, addressesArr, tokenDecimal } = props;
  const [currentItems, setCurrentItems] = useState<txnDatabyHashType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(txnDataOutput.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(txnDataOutput.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, txnDataOutput]);

  const handlePageClick = (event: any) => {
    const { id } = event.target;
    var newOffset;
    var pageNo = currentPage;
    switch (id) {
      case "next":
        pageNo = Math.min(pageNo + 1, pageCount);
        newOffset = ((pageNo - 1) * itemsPerPage) % txnDataOutput.length;
        break;
      case "prev":
        pageNo = Math.max(pageNo - 1, 1);
        newOffset = ((pageNo - 1) * itemsPerPage) % txnDataOutput.length;
        break;
      default:
        pageNo = parseInt(id);
        newOffset = ((pageNo - 1) * itemsPerPage) % txnDataOutput.length;
    }
    setCurrentPage(pageNo);
    setItemOffset(newOffset);
  };

  const PageList = () => {
    const Pages = [];
    if (currentPage >= 1 && currentPage <= pageCount) {
      for (let i = Math.max(currentPage - 1, 2); i <= Math.min(currentPage + 1, pageCount - 1); i++) {
        Pages.push(i);
      }
    }
    return (
      <div className="w-full flex   flex-row justify-between">
        <button
          id="1"
          className={`py-2 px-3 text-sm font-medium border  border-primary focus:ring-1 ring-secondary focus:outline-none  ${
            currentPage === 1 ? "bg-primary text-primary-content" : "bg-base-100 hover:bg-primary hover:text-primary-content "
          }`}
          onClick={handlePageClick}
        >
          1
        </button>
        ...
        <div className="flex flex-row justify-center">
          {pageCount > 1 &&
            Pages.map((pageno) => (
              <button
                key={`PaginationNav/${pageno}`}
                id={pageno.toString()}
                className={`py-2 px-3 text-sm font-medium border  border-primary focus:ring-1 ring-secondary focus:outline-none  ${
                  currentPage === pageno ? "bg-primary text-primary-content" : "bg-base-100 hover:bg-primary hover:text-primary-content "
                }`}
                onClick={handlePageClick}
              >
                {pageno}
              </button>
            ))}
        </div>
        ...
        {pageCount > 1 && (
          <button
            id={pageCount.toString()}
            className={`py-2 px-3 text-sm font-medium border  border-primary  focus:ring-1 ring-secondary focus:outline-none ${
              currentPage === pageCount ? "bg-primary text-primary-content" : "bg-base-100 hover:bg-primary hover:text-primary-content "
            }`}
            onClick={handlePageClick}
          >
            {pageCount}
          </button>
        )}
      </div>
    );
  };

  const PaginatedNav = () => {
    return (
      <div id="paginatedtext" className="flex flex-col items-center  w-full   ">
        <span className="text-sm ">
          Showing <span className="font-semibold ">{Intl.NumberFormat().format(itemOffset + 1)}</span>
          <span> to </span>
          <span className="font-semibold ">{Intl.NumberFormat().format(Math.min(itemOffset + itemsPerPage, txnDataOutput.length))}</span>
          <span> of </span>
          <span className="font-semibold ">{Intl.NumberFormat().format(txnDataOutput.length)}</span> Entries
        </span>

        <div id="paginatedbuttons" className="inline-flex mt-2 xs:mt-0 mb-5 justify-between w-full min-w-fit md:min-w-fit md:w-[40%]">
          <button
            id="prev"
            onClick={handlePageClick}
            className="py-2 px-4 text-sm font-medium rounded-l-lg text-base-content bg-base-100 border  border-primary hover:bg-primary hover:text-primary-content focus:ring-1 ring-secondary focus:outline-none"
          >
            Prev
          </button>
          {PageList()}
          <button
            id="next"
            onClick={handlePageClick}
            className="py-2 px-4 text-sm font-medium rounded-r-lg text-base-content bg-base-100 border  border-primary hover:bg-primary hover:text-primary-content focus:ring-1 ring-secondary focus:outline-none"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {PaginatedNav()}
      <div id="txnbyhash" className={` flex flex-wrap  mx-1 lg:mx-4 justify-start`}>
        <TransactionByHash {...{ fetchingData, currentItems, addressesArr, tokenDecimal }} />
      </div>
    </>
  );
};

export default TransactionByHashPaginate;
