import { BigNumber, ethers } from "ethers";
import axios from "axios";
import type { addressInputObj, totalValueType, collectionEntryType, txnDatabyBlockType } from "../utils/types";

//Contains utility functions for the main app

/**  Address Checking Function
 * @function checkAddressArr Checks Addresses if valid Ethereum address
 * @param addressInputArr Array of Objects containing input data for each address input field
 * @returns Updated Array of Objects with resolved addresses or error messages
 */
export const checkAddressArr = async (addressInputArr: addressInputObj[]) => {
  const list = [...addressInputArr].filter((address) => address.input !== "");

  let provider: ethers.providers.Web3Provider | null = null;
  if (typeof window.ethereum !== "undefined") provider = new ethers.providers.Web3Provider(window.ethereum);
  const backendProvider = ethers.getDefaultProvider("homestead");
  var isAddress = false;
  for (let index = 0; index < list.length; index++) {
    try {
      if (list[index].input.slice(list[index].input.length - 4, list[index].input.length).toLowerCase() === ".eth") {
        //resolve ENS into address first
        let ensAdd =
          (await provider?.resolveName(list[index].input))?.toLowerCase() || (await backendProvider.resolveName(list[index].input))?.toLowerCase();
        if (ensAdd) isAddress = ethers.utils.isAddress(ensAdd);
        if (isAddress && ensAdd) {
          list[index].address = ensAdd;
          list[index].ensname = list[index].input;
          list[index].status = "ok";
          list[index].error = false;
          list[index].ok = true;
        } else {
          list[index].address = "";
          list[index].ensname = "";
          list[index].status = "ENS Input did not resolve into valid ETH address";
          list[index].error = true;
          list[index].ok = false;
        }
      } else {
        //normal address check
        isAddress = ethers.utils.isAddress(list[index].input);
        if (isAddress) {
          list[index].address = list[index].input;
          list[index].ensname = "";
          list[index].status = "ok";
          list[index].error = false;
          list[index].ok = true;
        } else {
          list[index].address = "";
          list[index].ensname = "";
          list[index].status = "This is not a valid ETH address";
          list[index].error = true;
          list[index].ok = false;
        }
      }
    } catch (err: any) {
      list[index].address = "";
      list[index].ensname = "";
      list[index].status = err.message;
      list[index].error = true;
      list[index].ok = false;
    }
  }

  return list;
};

/**  Total Stats Summation Function
 * @function getTotalValue Sums Total Values for Gas, Eth/ERC20 in and Out, and the consequent net value (in - out - gas)
 * @param collections Object of collections
 * @returns Object containing the summed up values for ETH/ERC20 in, out , gas, and net value
 */
export const getTotalValue = async (collections: { [key: string]: collectionEntryType }) => {
  let totalValueObj: totalValueType = {
    totalgas: BigNumber.from(0),
    netvalue: {},
    valuein: {},
    valueout: {},
    unrealizedvalue: "",
    usdtotalgas: 0,
    usdnetvalue: 0,
    usdvaluein: 0,
    usdvalueout: 0,
    usdunrealizedvalue: 0,
  };

  Object.keys(collections).forEach((collectionName) => {
    totalValueObj.totalgas = totalValueObj.totalgas.add(collections[collectionName].totalgas);
    totalValueObj.unrealizedvalue = (Number(totalValueObj.unrealizedvalue) + Number(collections[collectionName].unrealizedvalue)).toString();

    Object.keys(collections[collectionName].netvalue).forEach((token) => {
      if (!totalValueObj.netvalue[token]) totalValueObj.netvalue[token] = BigNumber.from(0);
      totalValueObj.netvalue[token] = totalValueObj.netvalue[token].add(collections[collectionName].netvalue[token]);
    });

    Object.keys(collections[collectionName].valuein).forEach((token) => {
      if (!totalValueObj.valuein[token]) totalValueObj.valuein[token] = BigNumber.from(0);
      totalValueObj.valuein[token] = totalValueObj.valuein[token].add(collections[collectionName].valuein[token]);
    });

    Object.keys(collections[collectionName].valueout).forEach((token) => {
      if (!totalValueObj.valueout[token]) totalValueObj.valueout[token] = BigNumber.from(0);
      totalValueObj.valueout[token] = totalValueObj.valueout[token].add(collections[collectionName].valueout[token]);
    });

    //USD calcs
    totalValueObj.usdtotalgas += collections[collectionName].usdtotalgas;
    totalValueObj.usdnetvalue += collections[collectionName].usdnetvalue;
    totalValueObj.usdtotalgas += collections[collectionName].usdtotalgas;
    totalValueObj.usdunrealizedvalue += collections[collectionName].usdunrealizedvalue;

    collections[collectionName].usdin.forEach((usdAmt) => {
      totalValueObj.usdvaluein += usdAmt;
    });
    collections[collectionName].usdout.forEach((usdAmt) => {
      totalValueObj.usdvalueout += usdAmt;
    });
  });

  return totalValueObj;
};

/**  Uploading of NFT Data to DB
 * @function uploadNFTcontracts Uploads NFT collection data data to DB
 * @param collections Object of collections
 * @param nftContractData previous fetched contractdata
 */
export const uploadNFTcontracts = async (
  collections: { [key: string]: collectionEntryType },
  nftContractData: { name: string; address: string; slug: string; scam: boolean }[]
) => {
  let nftcontracts: { name: string; address: string; slug: string; scam: boolean }[] = []; //array of nftcontract objects
  Object.keys(collections).forEach((nftname) => {
    if (nftname === "nfttrader") return;
    const nftcontractObj = {
      name: nftname,
      address: collections[nftname].contractAddress,
      slug: collections[nftname].slug,
      scam: collections[nftname].scam,
    };
    const checkExist = nftContractData.findIndex((nft) => nft.address.toLowerCase() === collections[nftname].contractAddress.toLowerCase());
    if (checkExist !== -1) {
      const existingData = nftContractData[checkExist];
      if (JSON.stringify(existingData) === JSON.stringify(nftcontractObj)) return;
      else nftcontracts.push(nftcontractObj);
    } else {
      nftcontracts.push(nftcontractObj);
    }
  });
  if (nftcontracts.length > 0)
    try {
      await axios.post(" https://ddss-api.mongodillo.workers.dev/db/nftcontracts", nftcontracts);
      console.log("nftdata uploaded to db");
    } catch (err: any) {
      console.log(err);
    }
  else console.log("no new contracts to upload");
};

/**  Uploading of Block Data to DB
 * @function uploadTxBlockData Uploads Block data (timestamp, gas etc) to DB
 * @param txnDatabyBlock Array of block data including hashes
 */
export const uploadTxBlockData = async (txnDatabyBlock: txnDatabyBlockType[]) => {
  let blockDataToUpload = txnDatabyBlock.reduce((newArr: txnDatabyBlockType[], txn: txnDatabyBlockType) => {
    let finalArr = [...newArr];
    const blockNumIndex = finalArr.findIndex((blockObj) => blockObj.blockNum === txn.blockNum);
    if (blockNumIndex !== -1) {
      finalArr[blockNumIndex] = {
        ...finalArr[blockNumIndex],
        txnHashes: Array.from(new Set([...finalArr[blockNumIndex].txnHashes, ...txn.txnHashes])),
      };
    } else {
      finalArr = [...finalArr, txn];
    }

    return finalArr;
  }, [] as txnDatabyBlockType[]);

  blockDataToUpload.forEach((block, index) => {
    const map = new Map();
    const { txnHashes } = block;
    for (const hash of txnHashes) {
      map.set(JSON.stringify(hash), hash);
    }
    const uniqueHashes = [...Array.from(map.values())];
    blockDataToUpload[index].txnHashes = uniqueHashes;
  });

  if (blockDataToUpload.length > 0) {
    let uploadCount = Math.ceil(blockDataToUpload.length / 1000);

    for (let i = 0; i < uploadCount; i++) {
      let endNo = (i + 1) * 1000;
      let startNo = i * 1000;
      if (i === uploadCount - 1) endNo = blockDataToUpload.length;
      const subBlockToUpload = blockDataToUpload.slice(startNo, endNo);

      try {
        await axios.post(" https://ddss-api.mongodillo.workers.dev/db/blockdata", subBlockToUpload);
        console.log(`${i + 1}/${uploadCount} block data uploaded to db`);
      } catch (err: any) {
        console.log(err);
      }
    }
  }
};

/**  Fetch NFT Data from DB in smaller parts to avoid api errors
 * @function fetchNftContractsData Fetch NFT Data from DB in smaller parts to avoid api errors
 * @param uniqueNftAdds Array of unique nft contract addresses
 */
export const fetchNftContractsData = async (uniqueNftAdds: string[]) => {
  let nftContractData: {
    name: string;
    address: string;
    slug: string;
    scam: boolean;
  }[] = [];
  let promiseArr: any[] = [];

  if (uniqueNftAdds.length > 0) {
    let maxPerFetch = 2000;
    let delay = 200;
    let fetchCount = Math.ceil(uniqueNftAdds.length / maxPerFetch);

    for (let i = 0; i < fetchCount; i++) {
      let endNo = (i + 1) * maxPerFetch;
      let startNo = i * maxPerFetch;
      if (i === fetchCount - 1) endNo = uniqueNftAdds.length;
      const subArr = uniqueNftAdds.slice(startNo, endNo);

      const fetchPromise = new Promise(async (resolve, reject) => {
        //setTimeout(async () => {
        try {
          const fetchNftData = await axios.post("https://ddss-api.mongodillo.workers.dev/db/nftcontracts/getspecific", subArr);
          console.log(`${i + 1}/${fetchCount} of Nft Data fetched`);
          return resolve(fetchNftData.data.data);
        } catch (err: any) {
          console.log(err);
          return reject(err);
        }
        // }, delay * i);
      });
      promiseArr.push(fetchPromise);
    }
    const resultsArr = await Promise.all(promiseArr);

    resultsArr.forEach((result) => (nftContractData = nftContractData.concat(result)));
  }

  return nftContractData;
};

/**  Fetch Block Data from DB in smaller parts to avoid api errors
 * @function fetchBlockData Fetch Block Data from DB in smaller parts to avoid api errors
 * @param uniqueBlockNums Array of unique blockNums in hexstring
 */
export const fetchBlockData = async (uniqueBlockNums: string[]) => {
  let blockData: txnDatabyBlockType[] = [];
  let promiseArr: any[] = [];
  if (uniqueBlockNums.length > 0) {
    let maxPerFetch = 2000;
    let delay = 200;
    let fetchCount = Math.ceil(uniqueBlockNums.length / maxPerFetch);

    for (let i = 0; i < fetchCount; i++) {
      let endNo = (i + 1) * maxPerFetch;
      let startNo = i * maxPerFetch;
      if (i === fetchCount - 1) endNo = uniqueBlockNums.length;
      const subArr = uniqueBlockNums.slice(startNo, endNo);

      const fetchPromise = new Promise(async (resolve, reject) => {
        // setTimeout(async () => {
        try {
          const fetchBlockData = await axios.post("https://ddss-api.mongodillo.workers.dev/db/blockdata/getspecific", subArr);
          console.log(`${i + 1}/${fetchCount} of Block Data fetched`);
          return resolve(fetchBlockData.data.data);
        } catch (err: any) {
          console.log(err);
          return reject(err);
        }
        // }, delay * i);
      });
      promiseArr.push(fetchPromise);
    }
    const resultsArr = await Promise.all(promiseArr);

    resultsArr.forEach((result) => (blockData = blockData.concat(result)));
  }

  return blockData;
};
