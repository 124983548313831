const Header = () => {
  return (
    <header className=" navbar">
      <div className=" flex flex-col w-full justify-center">
        <span className=" btn btn-ghost btn-sm normal-case text-xl no-animation hover:cursor-default hover:bg-transparent ">demand supply</span>
        <span className=" btn btn-ghost btn-sm normal-case text-xl no-animation hover:cursor-default hover:bg-transparent ">
          https://ddss.mongodillo.dev
        </span>
      </div>
    </header>
  );
};

export default Header;
