import React from "react";
import { ethers } from "ethers";

import type { txnDatabyHashType } from "../utils/types";

const TransactionByHash = (props: {
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
  currentItems: txnDatabyHashType[];
  addressesArr: string[];
  tokenDecimal: { [key: string]: number };
}) => {
  const { fetchingData, currentItems, addressesArr, tokenDecimal } = props;
  return (
    <div className=" flex w-full overflow-x-auto ">
      <table id="fulltxn" className="table table-compact w-full table-zebra  md:tablefixed  text-center">
        <thead>
          <tr>
            <th className=" md:w-28 ">Date</th>
            <th className=" md:w-28 ">Tx Hash</th>
            <th className=" md:w-24 ">Tx Gas</th>
            <th className=" md:w-28">Movement</th>
            <th>Asset</th>
            <th className=" md:w-24">Amount</th>
          </tr>
        </thead>

        <tbody>
          {currentItems.map((txn, index) => (
            <React.Fragment key={`txtable/${index}`}>
              <tr className="border-t border-b border-base-content">
                <td rowSpan={txn.transactions?.length}>{txn.timestamp}</td>
                <td rowSpan={txn.transactions?.length}>
                  <a className="underline hover:text-primary" href={`https://etherscan.io/tx/${txn.hash}`} target="_blank" rel="noreferrer">
                    {txn.hash && String(txn.hash).substring(0, 4) + "..." + String(txn.hash).substring(txn.hash.length - 2)}
                  </a>
                </td>
                <td rowSpan={txn.transactions?.length}>{txn.gas && Number(ethers.utils.formatEther(txn.gas)).toFixed(3)}</td>
                {txn.transactions?.length && (
                  <>
                    {/*txn.transactions[0].from?.toLowerCase() === addressObj.address.toLowerCase() && <td>Outgoing</td>*/}
                    {txn.transactions[0].from &&
                      txn.transactions[0].to &&
                      addressesArr.includes(txn.transactions[0].from.toLowerCase()) &&
                      !addressesArr.includes(txn.transactions[0].to.toLowerCase()) && <td>Outgoing</td>}
                    {txn.transactions[0].from &&
                      txn.transactions[0].to &&
                      addressesArr.includes(txn.transactions[0].from.toLowerCase()) &&
                      addressesArr.includes(txn.transactions[0].to.toLowerCase()) && <td>Inter-Wallet Transfer</td>}
                    {/*txn.transactions[0].to?.toLowerCase() === addressObj.address.toLowerCase() && <td>Incoming</td>*/}
                    {txn.transactions[0].from &&
                      txn.transactions[0].to &&
                      !addressesArr.includes(txn.transactions[0].from.toLowerCase()) &&
                      addressesArr.includes(txn.transactions[0].to.toLowerCase()) && <td>Incoming</td>}
                    <td className="break-all ">
                      {txn.transactions[0].nftname || txn.transactions[0].tokenname} {txn.transactions[0].nftname && <> / </>}
                      {txn.transactions[0].nfttokenid} {txn.transactions[0].assettype === "nfttradertoken" ? "*NFTTrader" : ""}
                    </td>
                    <td>
                      {txn.transactions[0].nftqty ||
                        (txn.transactions[0].tokenvalue &&
                          txn.transactions[0].tokenname &&
                          Number(ethers.utils.formatUnits(txn.transactions[0].tokenvalue, tokenDecimal[txn.transactions[0].tokenname])).toFixed(3))}
                    </td>
                  </>
                )}
              </tr>
              {txn.transactions?.map((transaction, txindex) => (
                <React.Fragment key={`transactiontable/${index}/${txindex}`}>
                  {txindex >= 1 && (
                    <>
                      <tr>
                        {/*transaction.from?.toLowerCase() === addressObj.address.toLowerCase() && <td>Outgoing</td>*/}
                        {transaction.from &&
                          transaction.to &&
                          addressesArr.includes(transaction.from.toLowerCase()) &&
                          !addressesArr.includes(transaction.to.toLowerCase()) && <td>Outgoing</td>}
                        {transaction.from &&
                          transaction.to &&
                          addressesArr.includes(transaction.from.toLowerCase()) &&
                          addressesArr.includes(transaction.to.toLowerCase()) && <td>Inter-Wallet Transfer</td>}
                        {/*transaction.to?.toLowerCase() === addressObj.address.toLowerCase() && <td>Incoming</td>*/}
                        {transaction.from &&
                          transaction.to &&
                          !addressesArr.includes(transaction.from.toLowerCase()) &&
                          addressesArr.includes(transaction.to.toLowerCase()) && <td>Incoming</td>}
                        <td>
                          {transaction.nftname || transaction.tokenname} {transaction.nftname && <> / </>} {transaction.nfttokenid}
                          {transaction.assettype === "nfttradertoken" ? "*NFTTrader" : ""}
                        </td>
                        <td>
                          {transaction.nftqty ||
                            (transaction.tokenvalue &&
                              transaction.tokenname &&
                              Number(ethers.utils.formatUnits(transaction.tokenvalue, tokenDecimal[transaction.tokenname])).toFixed(3))}
                        </td>
                      </tr>
                    </>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionByHash;
