import type { statusStepType } from "../utils/types";
const StepModal = (prop: {
  statusStep: statusStepType;
  setStatusStep: React.Dispatch<React.SetStateAction<statusStepType>>;
  genTxHistory: () => Promise<void>;
  statusLog: string;
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
}) => {
  const { statusStep, setStatusStep, genTxHistory, statusLog, fetchingData } = prop;
  return (
    <div className={`modal modal-middle ${statusStep.modal ? "modal-open" : ""}`}>
      <div className="modal-box   border">
        <div className="flex justify-center gap-5 mb-5">
          {statusStep.appstatus === "" && (
            <h1 className="btn text-lg  btn-ghost gap-3  no-animation hover:cursor-default hover:bg-transparent ">
              Running App
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                className={`fill-current  ${fetchingData.unrealized ? "animate-spin" : ""}`}
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                <path
                  fillRule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                />
              </svg>
            </h1>
          )}
          {statusStep.appstatus === "success" && (
            <button className="btn  btn-sm btn-success" onClick={() => setStatusStep((prevState) => ({ ...prevState, modal: false }))}>
              See Results
            </button>
          )}
          {statusStep.appstatus === "error" && (
            <>
              <button className="btn btn-sm btn-error" onClick={genTxHistory}>
                Retry
              </button>
              <button className="btn btn-sm btn-primary" onClick={() => setStatusStep((prevState) => ({ ...prevState, modal: false }))}>
                Close
              </button>
            </>
          )}
        </div>

        <p className="text-sm mt-2">{statusLog}</p>
        <ul className="steps steps-vertical text-base">
          <li className={`step ${statusStep["1"]}`}>Checking Addresses</li>
          <li className={`step ${statusStep["2"]}`}>Fetching Transactions</li>
          <li className={`step ${statusStep["3"]}`}>Populating Txn Data</li>
          <li className={`step ${statusStep["4"]}`}>Updating NFT Names</li>
          <li className={`step ${statusStep["5"]}`}>Getting Gas Data</li>
          <li className={`step ${statusStep["6"]}`}>Sorting by Txn Hash</li>
          <li className={`step ${statusStep["7"]}`}>Sorting into Collections</li>
          <li className={`step ${statusStep["8"]}`}>Calculating Realized Value</li>
          <li className={`step ${statusStep["9"]}`}>Fetching Floors</li>
          <li className={`step ${statusStep["10"]}`}>Calculating Unrealized Value</li>
          <li className={`step ${statusStep["11"]}`}>Generating Final Results</li>
        </ul>
        {/*<div className="modal-action mt-1 justify-center gap-5">
          {statusStep.appstatus === "success" && (
            <button className="btn  btn-sm btn-success" onClick={() => setStatusStep((prevState) => ({ ...prevState, modal: false }))}>
              See Results
            </button>
          )}
          {statusStep.appstatus === "error" && (
            <>
              <button className="btn btn-sm btn-error" onClick={genTxHistory}>
                Retry
              </button>
              <button className="btn btn-sm btn-primary" onClick={() => setStatusStep((prevState) => ({ ...prevState, modal: false }))}>
                Close
              </button>
            </>
          )}
        </div>*/}
      </div>
    </div>
  );
};

export default StepModal;
