import React from "react";
import { ethers } from "ethers";

import type { totalValueType } from "../utils/types";

const TotalStats = (props: {
  totalValue: totalValueType;
  tokenDecimal: { [key: string]: number };
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
}) => {
  const { totalValue, tokenDecimal, fetchingData } = props;

  return (
    <div id="totalStats" className="w-full flex flex-col gap-2 ">
      <div className="flex flex-col gap-1 text-center">
        <h1 className="flex flex-col md:flex-row md:gap-2 items-center text-xl justify-center md:mb-0 mb-3">
          {totalValue.usdnetvalue >= 0 && (
            <>
              You have a <span className=" font-bold text-success text-xl uppercase"> realized profit</span> of
              <span className="font-bold text-success text-2xl">USD {Intl.NumberFormat().format(Math.round(totalValue.usdnetvalue))}</span>
            </>
          )}
          {totalValue.usdnetvalue < 0 && (
            <>
              You have a <span className=" font-bold text-error text-xl uppercase"> realized loss</span> of
              <span className="font-bold text-error text-2xl">USD {Intl.NumberFormat().format(Math.round(totalValue.usdnetvalue))}</span>
            </>
          )}

          {!totalValue.usdnetvalue && (
            <>
              <span className=" font-bold text-error text-2xl uppercase"> Error calculating realized USD position</span>
            </>
          )}
        </h1>

        <h1 className="flex flex-col md:flex-row md:gap-2 items-center text-xl justify-center md:mb-0 mb-3">
          Your NFT assets are valued at
          <span className={`font-bold  text-2xl ${fetchingData.unrealized ? "animate-pulse bg-base-content text-base-content" : "text-warning"}`}>
            USD {Intl.NumberFormat().format(Math.round(totalValue.usdunrealizedvalue))}
          </span>
          at current floor prices
        </h1>

        <p className=" text-sm italic">
          *USD conversions are based on closing daily ETH prices for each transaction and only captures ETH/WETH transactions for now.{" "}
        </p>
      </div>

      <div className="btn  btn-lg btn-secondary no-animation btn-block btn-active hover:cursor-default">NFT Portfolio Stats</div>

      <div className="stats lg:stats-horizontal stats-vertical bg-neutral text-neutral-content w-full ">
        <div className="stat text-center">
          <div className="stat-title">ETH/ERC20 Inflow</div>

          {Object.keys(totalValue.valuein).map((token, index) => (
            <React.Fragment key={`totalvalue/valuein/${index}`}>
              <div className="stat-value font-medium text-xl">
                {token}: {Number(ethers.utils.formatUnits(totalValue.valuein[token], tokenDecimal[token])).toFixed(2)}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="stat text-center">
          <div className="stat-title">ETH/ERC20 Outflow</div>

          {Object.keys(totalValue.valueout).map((token, index) => (
            <React.Fragment key={`totalvalue/valueout/${index}`}>
              <div className="stat-value font-medium text-xl">
                {token}: {Number(ethers.utils.formatUnits(totalValue.valueout[token], tokenDecimal[token])).toFixed(2)}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="stat text-center">
          <div className="stat-title">Total Gas Spent</div>
          <div className="stat-value font-medium text-xl">ETH: {Number(ethers.utils.formatEther(totalValue.totalgas)).toFixed(2)}</div>
        </div>
        <div className="stat text-center">
          <div className="stat-title">Net ETH/ERC20 Realized P&L</div>
          {Object.keys(totalValue.netvalue).map((token, index) => (
            <React.Fragment key={`totalvalue/netvalue/${index}`}>
              <div className="stat-value font-medium text-xl">
                {token}: {Number(ethers.utils.formatUnits(totalValue.netvalue[token], tokenDecimal[token])).toFixed(2)}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="stat text-center">
          <div className="stat-title">Current NFT Holdings Value (at Floor)</div>
          <div className={`stat-value font-medium text-xl ${fetchingData.unrealized ? "animate-pulse bg-base-content text-base-content" : ""}`}>
            ETH: {Number(totalValue.unrealizedvalue).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalStats;
