import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { BigNumber, ethers } from "ethers";
import axios from "axios";

import TransactionByHashPaginate from "./components/TransactionByHashPaginate";
import TotalStats from "./components/TotalStats";
import AddressInput from "./components/AddressInput";
import ResultsNav from "./components/ResultsNav";
import CollectionStats from "./components/CollectionStats";
import Footer from "./components/Footer";
import Header from "./components/Header";
import StepModal from "./components/StepModal";

import scamAdds from "./data/scamAdds.json"; //array of known scam addresses - can be manually updated

import { checkAddressArr, getTotalValue, uploadNFTcontracts, uploadTxBlockData, fetchNftContractsData, fetchBlockData } from "./utils/utils";
import {
  txnType,
  txnDatabyHashType,
  collectionEntryType,
  totalValueType,
  addressInputObj,
  fetchedDataType,
  statusStepType,
  txnDatabyBlockType,
} from "./utils/types";

const NFTTraderAdds = ["0x13d8faf4a690f5ae52e2d2c52938d1167057b9af".toLowerCase(), "0xc310e760778ecbca4c65b6c559874757a4c4ece0".toLowerCase()];

/*
Data on blockNum and NFT Contract names are stored in DB and called only when app is run, fetching only specific data found in wallet where available.
Data is updated after each app run if there are new inserts.
*/

function App() {
  const [addressInputArr, setAddressInputArr] = useState<addressInputObj[]>([
    { input: "", address: "", ensname: "", status: "", error: false, ok: false },
  ]);
  const [addressesArr, setAddressesArr] = useState<string[]>([""]);

  const [ethPriceFetch, setEthethPriceFetch] = useState<{ [key: string]: string }>({}); // fetched ETH prices

  const [txnDataHash, setTxnDataHash] = useState<txnDatabyHashType[]>([]);

  const [filterSelect, setFilterSelect] = useState<{ [key: string]: string }>({}); //selected filter options
  const [txnDataOutput, setTxnDataOutput] = useState<txnDatabyHashType[]>([]); //filtered output

  const [collectionsObj, setCollectionsObj] = useState<{ [key: string]: collectionEntryType }>({}); // contains all collections stats required

  const [totalValue, setTotalValue] = useState<totalValueType>({
    totalgas: BigNumber.from(0),
    netvalue: {},
    valuein: {},
    valueout: {},
    unrealizedvalue: "",
    usdtotalgas: 0,
    usdnetvalue: 0,
    usdvaluein: 0,
    usdvalueout: 0,
    usdunrealizedvalue: 0,
  }); //state to hold cumulative value of entire portfolio

  const [tokenDecimal, setTokenDecimal] = useState<{ [key: string]: number }>({});

  const [fetchingData, setFetchingData] = useState({ unrealized: false, realized: false, button: false });
  const [showResults, setShowResults] = useState(false);

  const [statusLog, setStatusLog] = useState("");
  const [statusStep, setStatusStep] = useState<statusStepType>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
    modal: false,
    appstatus: "",
  });
  const [fetchedDataState, setFetchedDataState] = useState<fetchedDataType>({
    // to store alchemy txn data and floor prices temporarily
    addresses: [],
    alchtxndata: [],
    collectionsFloor: {},
    addChange: true,
  });

  useEffect(() => {
    //fetch ETH prices first
    const fetchETH = async () => {
      try {
        console.log("Fetching ETH Prices");
        const fetchETHpx = await axios.get(`https://ddss-api.mongodillo.workers.dev/db/prices`); // to add a get endpoint to cf worker under DB.
        setEthethPriceFetch(fetchETHpx.data.data[0]);
      } catch (err: any) {
        console.log("Error Fetching Prices", err);
      }
    };

    fetchETH();
  }, []);

  const genTxHistory = async () => {
    //data fetched from db
    let nftContractData: { name: string; address: string; slug: string; scam: boolean }[] = []; //array of fetched nft data from db
    let blockData: txnDatabyBlockType[] = []; // array of fetched block data from db
    let ethPrices: { [key: string]: string } = {}; // object of historical ETH prices in USD

    //data stored from app
    let txnDatabyBlock: txnDatabyBlockType[] = []; // txn Data sorted at block level to upload
    let txnDatabyHash: txnDatabyHashType[] = []; //txn Data sorted at hash level
    let txnDataFull: txnType[] = []; //array of all individual txns, not sorted by hash
    let fullfetchdata: any[] = []; //array of all txn data fetched concat
    let tokenDecimalObj: { [key: string]: number } = {}; // object of ERC20 tokens and corresponding decimal places

    let fetchedData = fetchedDataState; //retrieve any local stored alch txn data and floor price if no update

    console.time("getTxHistory");
    setShowResults(true);
    setFetchingData({ unrealized: true, realized: true, button: true });
    setTxnDataOutput([]);
    setTotalValue({
      totalgas: BigNumber.from(0),
      netvalue: {},
      valuein: {},
      valueout: {},
      unrealizedvalue: "",
      usdtotalgas: 0,
      usdnetvalue: 0,
      usdvaluein: 0,
      usdvalueout: 0,
      usdunrealizedvalue: 0,
    });
    setStatusStep({ 1: "", 2: "", 3: "", 4: "", 5: "", 6: "", 7: "", 8: "", 9: "", 10: "", 11: "", modal: true, appstatus: "" });

    //checks ETH prices to see if they fetched properly, else fetch again
    ethPrices = ethPriceFetch;
    if (ethPrices === {})
      try {
        console.log("Fetching ETH Prices");
        const fetchETHpx = await axios.get(`https://ddss-api.mongodillo.workers.dev/db/prices`); // to add a get endpoint to cf worker under DB.
        ethPrices = fetchETHpx.data.data[0];
        setEthethPriceFetch(ethPrices);
      } catch (err: any) {
        console.log("Error Fetching Prices", err);
      }

    try {
      //const { address } = await getAddress(); //gets address from inputbox
      setStatusLog("Checking Addresses Input");
      setStatusStep((prevState) => ({ ...prevState, 1: "step-warning" }));
      const addressList = await checkAddressArr(addressInputArr);
      if (addressList.length < 1) throw new Error("Please Input Address");

      const findError = addressList.findIndex((entry) => entry.error === true);

      if (findError === -1) {
        //no error in Addresses
        setStatusStep((prevState) => ({ ...prevState, 1: "step-success" }));
        setAddressInputArr(addressList);
        const addresses = Array.from(new Set(addressList.map((entry) => entry.address.toLowerCase())));
        setAddressesArr(addresses);
        if (fetchedData.addresses.length === addresses.length && JSON.stringify(fetchedData.addresses) === JSON.stringify(addresses))
          //checks if addresses have changed since last request, else use cached data where available
          fetchedData = { ...fetchedData, addChange: false };
        else
          fetchedData = {
            addresses,
            alchtxndata: [],
            collectionsFloor: {},
            addChange: true,
          };

        //Populate TxnDataFull Array with fetching of data from Alchemy API
        console.log("Fetching Txn History");
        setStatusLog("Fetching Txn History");
        setStatusStep((prevState) => ({ ...prevState, 2: "step-warning" }));
        try {
          let TxFetchArr: any[] = []; //promise Array for fetching txn data for all
          if (fetchedData.addChange === false && fetchedData.alchtxndata.length > 0) {
            console.log("no change in address");
            fullfetchdata = fetchedData.alchtxndata; //just use the same txndata that was saved previously
          } else {
            console.log("address changed, so fetching!");
            addresses.forEach((address) => {
              const txFetch = new Promise(async (resolve, reject) => {
                try {
                  let fulldata: any[] = [];
                  const workerURL = "https://ddss-api.mongodillo.workers.dev/";
                  const gettxfrom = await axios.get(workerURL + "alchemy/gettxfrom?address=" + address);
                  const data = gettxfrom.data.data;
                  fulldata = fulldata.concat(data);

                  const gettxto = await axios.get(workerURL + "alchemy/gettxto?address=" + address);
                  const data2 = gettxto.data.data;
                  fulldata = fulldata.concat(data2);

                  return resolve(fulldata);
                } catch (err) {
                  console.log("Error Fetching Txn History for Address", address, err);
                  return reject(`Error fetching Txn History for ${address}. Please Retry`);
                }
              });
              TxFetchArr.push(txFetch);
            });

            // if addresses have changed or prev call failed, call API to get txnhistory and save to fetchedData

            const alchFetchArr = await Promise.all(TxFetchArr);
            alchFetchArr.forEach((alchData) => {
              fullfetchdata = fullfetchdata.concat(alchData);
            });
            fetchedData.alchtxndata = fullfetchdata;
          }

          fullfetchdata = fullfetchdata.sort((a: any, b: any) => a.blockNum - b.blockNum);
          setFetchedDataState((prevState) => ({ ...prevState, addresses: fetchedData.addresses, alchtxndata: fullfetchdata }));
          setStatusStep((prevState) => ({ ...prevState, 2: "step-success" }));
        } catch (err: any) {
          console.log("Fetching  Txn History", err);
          setStatusStep((prevState) => ({ ...prevState, 2: "step-error" }));
          throw new Error("Error Fetching Txn History. Please Retry");
        }

        console.log("Populating Txn Data Array");
        setStatusLog("Populating Txn Data Array");
        setStatusStep((prevState) => ({ ...prevState, 3: "step-warning" }));
        try {
          let nftContractAdds: string[] = []; //array of all Nft collection addresses
          let blockNumArr: string[] = []; //array of all blockNums in txns

          const fulldata = fullfetchdata;
          if (!fulldata) return;

          //to capture ETH spent via NFT Trader as there are instances where such transactions is separate from the actual NFT trade transaction
          const nftTraderFilter = fulldata.filter(
            (transaction: any) =>
              NFTTraderAdds.includes(transaction.from.toLowerCase()) && (transaction.asset === "WETH" || transaction.asset === "ETH")
          );

          nftTraderFilter.forEach((tx: any) => {
            const { hash, to, from, asset, blockNum } = tx;
            const txn: txnType = {
              blockNum,
              hash,
              to,
              from,
              tokenname: asset,
              tokenvalue: BigNumber.from(tx.rawContract.value),
              assettype: "nfttradertoken",
              tokencontract: tx.rawContract.address,
              gas: BigNumber.from(0),
              gasPrice: BigNumber.from(0),
              gasUsed: BigNumber.from(0),
              timestamp: "0",
              timestamphex: "0",
              hashfrom: "",
            };

            if (!tokenDecimalObj[asset]) tokenDecimalObj[asset] = 0;
            if (tx.rawContract.decimal) tokenDecimalObj[asset] = Number(BigNumber.from(tx.rawContract.decimal));
            blockNumArr.push(blockNum);
            txnDataFull.push(txn);
          });

          fulldata.forEach(async (tx: any, index: number) => {
            const { hash, to, from, asset, blockNum } = tx;

            if (tx.erc721TokenId !== null) {
              //erc721 transfer

              const txn: txnType = {
                blockNum,
                hash,
                to,
                from,
                nftname: asset,
                nftqty: 1,
                nfttokenid: parseInt(tx.erc721TokenId),
                assettype: "erc721",
                tokencontract: tx.rawContract.address,
                gas: BigNumber.from(0),
                gasPrice: BigNumber.from(0),
                gasUsed: BigNumber.from(0),
                timestamp: "0",
                timestamphex: "0",
                hashfrom: "",
              };

              if (asset === null) txn.nftname = tx.rawContract.address; // if not in collection, use address for now
              blockNumArr.push(blockNum);
              nftContractAdds.push(tx.rawContract.address);
              txnDataFull.push(txn);
            } else if (tx.erc1155Metadata !== null && tx.erc721TokenId === null) {
              //erc1155 transfer
              tx.erc1155Metadata.forEach((token: any, index: number) => {
                //to capture instances where a single txn had multiple erc1155 tokenids
                const txn: txnType = {
                  blockNum,
                  hash,
                  to,
                  from,
                  nftname: asset,
                  nftqty: parseInt(token.value.toString()),
                  nfttokenid: parseInt(token.tokenId),
                  assettype: "erc1155",
                  tokencontract: tx.rawContract.address,
                  gas: BigNumber.from(0),
                  gasPrice: BigNumber.from(0),
                  gasUsed: BigNumber.from(0),
                  timestamp: "0",
                  timestamphex: "0",
                  hashfrom: "",
                };

                if (asset === null) txn.nftname = tx.rawContract.address; // if not in collection, use address for now
                txnDataFull.push(txn);
              });

              blockNumArr.push(blockNum);
              nftContractAdds.push(tx.rawContract.address);
            } else {
              //erc20/eth transfer
              //see if the txn hash exists for erc1155 or erc721 also. if yes -> include, if no -> exclude.

              if (asset !== null) {
                //can be more specific on tokens if required (e.g. (asset === "WETH" || asset === "ETH" || asset === "SAND" || asset === "DAI"))
                const checkTxFilter = fulldata.filter((transaction: any) => transaction.hash === hash);
                if (checkTxFilter.length <= 1) return;

                const findNFTtx = checkTxFilter.filter(
                  (transaction: any) => transaction.erc721TokenId !== null || transaction.erc1155Metadata !== null
                );
                if (findNFTtx.length < 1) return;

                const txn: txnType = {
                  blockNum,
                  hash,
                  to,
                  from,
                  tokenname: asset,
                  tokenvalue: BigNumber.from(tx.rawContract.value),
                  assettype: "token",
                  tokencontract: tx.rawContract.address,
                  gas: BigNumber.from(0),
                  gasPrice: BigNumber.from(0),
                  gasUsed: BigNumber.from(0),
                  timestamp: "0",
                  timestamphex: "0",
                  hashfrom: "",
                };

                if (!tokenDecimalObj[asset]) tokenDecimalObj[asset] = 0;
                if (tx.rawContract.decimal) tokenDecimalObj[asset] = Number(BigNumber.from(tx.rawContract.decimal)); //stores decimal to token Name as key
                blockNumArr.push(blockNum);
                txnDataFull.push(txn);
              }
            }
          });

          setTokenDecimal(tokenDecimalObj);
          setStatusStep((prevState) => ({ ...prevState, 3: "step-success" }));

          let waitTime = 30000;
          if (txnDataFull.length < 5000) waitTime = 5000;
          console.log(`Cooldown for ${waitTime / 1000} seconds before fetching additional data`);
          setStatusLog(`Cooldown for ${waitTime / 1000} seconds before fetching additional data`);
          await new Promise((resolve) => {
            setTimeout(async () => {
              try {
                console.log("Cooldown over");
                setStatusLog(`Cooldown Over. Fetching existing relevant NFT Collection and Block Data`);
                try {
                  //fetch data on nfts linked to the wallet
                  const uniqueNftAdds = Array.from(new Set(nftContractAdds));

                  //const fetchNftData = await axios.post("https://api.ddss.app/db/nftcontracts/getspecific", uniqueNftAdds);
                  //nftContractData = fetchNftData.data.data;

                  nftContractData = await fetchNftContractsData(uniqueNftAdds);
                  console.log("Nft data fetched");
                } catch (err) {
                  console.log(err);
                }
                try {
                  //fetch data on blockNums
                  const uniqueBlockNums = Array.from(new Set(blockNumArr));

                  // const fetchBlockData = await axios.post("https://api.ddss.app/db/blockdata/getspecific", uniqueBlockNums);
                  // blockData = fetchBlockData.data.data;
                  blockData = await fetchBlockData(uniqueBlockNums);
                  console.log("Block data fetched");
                } catch (err) {
                  console.log(err);
                }
              } finally {
                resolve("fetch done");
              }
            }, waitTime);
          });
        } catch (err) {
          console.log("Populate TxnDataFull Array", err);
          setStatusStep((prevState) => ({ ...prevState, 3: "step-error" }));
          throw new Error("Error Populating Txn Array");
        }

        //UPDATE COLLECTION NAMES - uses LooksRare API to update unknown collection names

        console.log("Updating Collection Names");
        setStatusLog("Updating Collection Names");
        setStatusStep((prevState) => ({ ...prevState, 4: "step-warning" }));
        try {
          const getNameObj: { [key: string]: string } = {}; // to contain tokenContract as key and nft collection name as value
          const namelessArr = txnDataFull.filter((txn) => txn.nftname === txn.tokencontract);
          for (let i = 0; i < namelessArr.length; ++i) {
            const tx = namelessArr[i];
            const { tokencontract } = tx;
            if (!tokencontract) continue;

            if (getNameObj[tokencontract]) continue;

            const checkNftData = nftContractData.findIndex((nft) => nft.address.toLowerCase() === tokencontract.toLowerCase());
            if (checkNftData !== -1) {
              getNameObj[tokencontract] = nftContractData[checkNftData].name;
              continue;
            }

            const lrAPI = await axios.get("https://api.looksrare.org/api/v1/collections?address=" + tokencontract);
            const lrName = lrAPI.data.data.name;
            if (lrName) getNameObj[tokencontract] = lrName;
            else {
              getNameObj[tokencontract] = tokencontract;
            }
          }

          //Updates the affected txns in txnDataFull with the new nft collection name, replacing the "contract address" as name.
          Object.keys(getNameObj).forEach((tokenContract) => {
            const txnToUpdate = txnDataFull.filter((txn) => txn.nftname === tokenContract);
            txnToUpdate.forEach((txUpdate) => {
              const updateIndex = txnDataFull.findIndex((txn) => JSON.stringify(txn) === JSON.stringify(txUpdate));
              txnDataFull[updateIndex].nftname = getNameObj[tokenContract];
            });
          });

          setStatusStep((prevState) => ({ ...prevState, 4: "step-success" }));
        } catch (err) {
          console.log("Update Collection Names", err);
          setStatusStep((prevState) => ({ ...prevState, 4: "step-error" }));
          //throw new Error("Error Updating Collection Names");
        }

        //GET GAS and TIMESTAMP -  gets gas and the block timestamp for each txn hash
        console.log("Getting Gas and Timestamp data");
        setStatusLog("Getting Gas and Timestamp data - this may take awhile...");
        setStatusStep((prevState) => ({ ...prevState, 5: "step-warning" }));
        let gasTimeErr = false; // to detect if there is an error in the step for the Step colouring

        try {
          let reqArray: any[] = []; //Promise.all array population
          let promiseDelayInc = 250;
          let promiseCount = 0;

          txnDataFull.forEach(async (txn, index) => {
            if (!txn.hash) return;
            if (!txn.blockNum) return;

            const txdataReq = new Promise(async (resolve) => {
              if (!txn.hash) return;
              if (!txn.blockNum) return;
              let hashExists = false;
              //first check if blockData contains the necessary data to avoid repeated API calls.

              const findBlockNum = blockData.findIndex((block: txnDatabyBlockType) => block.blockNum === txn.blockNum);

              if (findBlockNum !== -1) {
                // block exists in db
                const findHashInBlock = blockData[findBlockNum].txnHashes.findIndex(
                  (tx: { hash: string; gasUsed: BigNumber; gasPrice: BigNumber; from: string }) => tx.hash === txn.hash
                );

                if (findHashInBlock !== -1) {
                  //hash exists within blockNum already
                  const timestamphex = blockData[findBlockNum].timestamp;
                  const { gasUsed, gasPrice, from } = blockData[findBlockNum].txnHashes[findHashInBlock];

                  const timestampBN = Number(BigNumber.from(timestamphex).toString()) || 0;

                  const timestampDate = new Date(timestampBN * 1000).toLocaleDateString("en-GB", { timeZone: "UTC" });

                  let gas: BigNumber;
                  if (addresses.includes(from)) {
                    gas = BigNumber.from(gasPrice).mul(BigNumber.from(gasUsed));
                  } else gas = BigNumber.from(0);

                  hashExists = true;
                  return resolve({
                    hash: txn.hash,
                    gas,
                    timestamp: timestampDate,
                    from,
                    gasUsed,
                    gasPrice,
                    blockNum: txn.blockNum,
                    timestamphex,
                  });
                }
              }
              if (!hashExists) {
                //if not then call worker

                setTimeout(async () => {
                  try {
                    const data = JSON.stringify({
                      hash: txn.hash,
                      id: index,
                      blockNum: txn.blockNum,
                    });
                    const init = {
                      method: "POST",
                      headers: {
                        "content-type": "application/json;charset=UTF-8",
                      },
                      timeout: 5000,
                    };

                    const txdataFetch = await axios.post(" https://ddss-api.mongodillo.workers.dev/ethrpc/txdata", data, init);

                    const { timestamp, from, gasUsed, effectiveGasPrice, blockNumber, txnHash, rpc } = txdataFetch.data.data;
                    const timestampBN = Number(BigNumber.from(timestamp).toString()) || 0;
                    const timestampDate = new Date(timestampBN * 1000).toLocaleDateString("en-GB", { timeZone: "UTC" });

                    let gas: BigNumber;
                    if (addresses.includes(from)) {
                      gas = BigNumber.from(effectiveGasPrice).mul(BigNumber.from(gasUsed));
                    } else gas = BigNumber.from(0);

                    const blockUpload: txnDatabyBlockType = {
                      blockNum: txn.blockNum,
                      timestamp,
                      txnHashes: [{ hash: txn.hash, gasUsed, gasPrice: effectiveGasPrice, from }],
                    };

                    txnDatabyBlock.push(blockUpload);

                    if (txnDatabyBlock.length % 2000 === 0) {
                      await uploadTxBlockData(txnDatabyBlock);
                    }

                    return resolve({
                      hash: txnHash,
                      gas,
                      timestamp: timestampDate,
                      from,
                      gasUsed,
                      gasPrice: effectiveGasPrice,
                      blockNum: blockNumber,
                      timestamphex: timestamp,
                    });
                  } catch (err: any) {
                    //reject(err);
                    console.log(err);
                    return resolve({
                      hash: txn.hash,
                      gas: BigNumber.from(0),
                      timestamp: "0",
                      from: "",
                      gasUsed: BigNumber.from(0),
                      gasPrice: BigNumber.from(0),
                      blockNum: txn.blockNum,
                      timestamphex: "0",
                    });
                  }
                }, Math.floor(promiseCount / 5) * promiseDelayInc);
                ++promiseCount;
              }
            });
            reqArray.push(txdataReq);
          });

          //executes the Promise.All array and updates txnDatabyHash and txnDataFull accordingly
          const reqResults = await Promise.all(reqArray);
          setStatusLog("Fetched Gas and Timestamp data - now populating ");
          console.log("Fetched Gas and Timestamp data - now populating ");
          let gasResultObj: { [key: string]: any } = {}; //mapping of all gas and timestamp result by hash

          reqResults.forEach((resultObj, index) => {
            const { hash, gas, timestamp, from, gasUsed, gasPrice, timestamphex } = resultObj;

            if (!gasResultObj[hash]) gasResultObj[hash] = { hash, gas, timestamp, from, gasUsed, gasPrice, timestamphex };

            /* 
           const findHashAll = txnDataFull.filter((tx: txnType) => tx.hash === hash);
            findHashAll.forEach((txn) => {
              const findHash = txnDataFull.findIndex((tx: txnType) => JSON.stringify(tx) === JSON.stringify(txn));
              txnDataFull[findHash].gas = gas;
              txnDataFull[findHash].timestamp = timestamp;
              txnDataFull[findHash].timestamphex = timestamphex;
              txnDataFull[findHash].hashfrom = from;
              txnDataFull[findHash].gasPrice = gasPrice;
              txnDataFull[findHash].gasUsed = gasUsed;
            });
            */
          });

          for (let i = 0; i < txnDataFull.length; ++i) {
            let hashObj = gasResultObj[txnDataFull[i].hash];
            txnDataFull[i].gas = hashObj.gas;
            txnDataFull[i].timestamp = hashObj.timestamp;
            txnDataFull[i].timestamphex = hashObj.timestamphex;
            txnDataFull[i].hashfrom = hashObj.from;
            txnDataFull[i].gasPrice = hashObj.gasPrice;
            txnDataFull[i].gasUsed = hashObj.gasUsed;
          }

          if (gasTimeErr) setStatusStep((prevState) => ({ ...prevState, 5: "step-error" }));
          else setStatusStep((prevState) => ({ ...prevState, 5: "step-success" }));
        } catch (err) {
          console.log("Update Gas and Timestamp", err);
          setStatusStep((prevState) => ({ ...prevState, 5: "step-error" }));
          // throw new Error("Error Updating Gas and Timestamp");
        }

        //Populate txnDatabyHash
        console.log("Sorting Txns by Hash and BlockNumber");
        setStatusLog("Sorting Txns by Hash and BlockNumber");
        setStatusStep((prevState) => ({ ...prevState, 6: "step-warning" }));
        try {
          txnDataFull.forEach(async (txn) => {
            const { hash, blockNum, gas, timestamp, hashfrom, gasUsed, gasPrice } = txn;
            if (!hash || !blockNum) return;

            //sort by Hash
            const findHashIndex = txnDatabyHash.findIndex((transaction) => transaction.hash === hash);
            if (findHashIndex === -1) txnDatabyHash.push({ hash, blockNum, timestamp, gas, gasUsed, gasPrice, from: hashfrom, transactions: [txn] });
            else txnDatabyHash[findHashIndex].transactions.push(txn);
          });
          setStatusStep((prevState) => ({ ...prevState, 6: "step-success" }));
        } catch (err) {
          console.log("txnDatabyHash Population", err);
          setStatusStep((prevState) => ({ ...prevState, 6: "step-error" }));
          throw new Error("Error Sorting by Hash");
        }
        txnDatabyHash.sort((a: any, b: any) => b.blockNum - a.blockNum); //most recent first

        const collections = await getCollections(txnDataFull, txnDatabyHash, addresses, nftContractData, ethPrices);
        if (!collections) throw new Error("Error With Collection Process");

        console.log("Generating Total Portfolio Value");
        setStatusLog("Generating Total Portfolio Value");
        setStatusStep((prevState) => ({ ...prevState, 11: "step-warning" }));
        const totalValueObj = await getTotalValue(collections);

        //console.log(collections);
        // console.log(totalValueObj);
        //console.log(txnDataFull);
        //console.log(txnDatabyHash);
        //console.log(tokenDecimalObj);

        setCollectionsObj(collections);
        setTotalValue(totalValueObj);
        setTxnDataHash(txnDatabyHash);
        setTxnDataOutput(txnDatabyHash);

        //upload data to DB
        await uploadNFTcontracts(collections, nftContractData);
        await uploadTxBlockData(txnDatabyBlock);

        setStatusLog("Results Ready");
        setStatusStep((prevState) => ({ ...prevState, 11: "step-success", appstatus: "success" }));
      } else {
        //error in Address Input
        setStatusStep((prevState) => ({ ...prevState, 1: "step-error" }));
        console.log("Address Contain an Error. Please Check");
        throw new Error("Addresses Contain an Error. Please Check");
      }
    } catch (err: any) {
      console.log("Generate Txn Function", err);
      setStatusLog(`Error Generating Txn History - ${err.message}`);
      setShowResults(false);
      setStatusStep((prevState) => ({ ...prevState, appstatus: "error" }));
    } finally {
      setFetchingData({ unrealized: false, realized: false, button: false });
      console.timeEnd("getTxHistory");
    }
  };

  const getCollections = async (
    txnDataFullArr: txnType[],
    txnDatabyHashArr: txnDatabyHashType[],
    addresses: string[],
    nftContractData: { name: string; address: string; slug: string; scam: boolean }[],
    ethPrices: { [key: string]: string }
  ) => {
    //gets object of all collections, tokenIds and contract addresses for each collection that wallet has ever held
    let collections: { [key: string]: collectionEntryType } = {};
    let LRfloorFetchArr: any[] = []; // array of Promises to fetch floor prices
    let OSfloorFetchArr: any[] = []; // array of Promises to fetch floor prices
    let floorError = false;
    let fetchedData = fetchedDataState;

    try {
      //COLLECTIONS OBJ SETUP
      console.log("Setting Up Collections Data");
      setStatusLog("Setting up Collections Data");
      setStatusStep((prevState) => ({ ...prevState, 7: "step-warning" }));
      try {
        txnDataFullArr.forEach((txn) => {
          const { assettype, gas, nftname, nfttokenid, tokencontract, from, to, tokenname, tokenvalue, nftqty, timestamp } = txn;
          if (assettype === "token") return;
          else if (assettype === "nfttradertoken") {
            //Handles nfttrader related txns which are not tagged to a collection due to individual txns
            if (!tokenname || !tokenvalue || !from || !to || !gas) return;
            if (!collections["nfttrader"])
              collections["nfttrader"] = {
                collectionName: "nfttrader-unclassified",
                contractAddress: "",
                slug: "",
                tokenIds: [],
                tokens: 0,
                ethin: [],
                ethout: [],
                netvalue: { ETH: BigNumber.from(0) },
                valuein: { ETH: BigNumber.from(0) },
                valueout: { ETH: BigNumber.from(0) },
                floor: { looksrare: "0", opensea: "0", lrerror: false, oserror: false, lrcached: false, oscached: false },
                gas: [],
                totalgas: BigNumber.from(0),
                balanceqty: "",
                unrealizedvalue: "",
                usdunrealizedvalue: 0,
                scam: false,
                txnHashes: [],
                usdin: [],
                usdout: [],
                usdgas: [],
                usdtotalgas: 0,
                usdnetvalue: 0,
              };
            collections["nfttrader"].gas.push(gas);

            if (addresses.includes(from.toLowerCase()) && !addresses.includes(to.toLowerCase())) {
              collections["nfttrader"].ethout.push({ asset: tokenname, value: tokenvalue });
              if (tokenname === "ETH" || tokenname === "WETH") {
                let ethPx: number;
                if (!ethPrices[timestamp]) {
                  console.error(`ETH price for ${timestamp} not available. Trying to use previous day or latest`);
                  //handle when timestamp is not in ethPrices yet -> check if date is in future then use latest
                  const splitTimestamp = timestamp.split("/");
                  const timestampMinusOneDay = new Date(
                    `${splitTimestamp[1]}/${Number(splitTimestamp[0]) - 1}'/${splitTimestamp[2]} UTC`
                  ).toLocaleDateString("en-gb", {
                    timeZone: "UTC",
                  });
                  if (!ethPrices[timestampMinusOneDay]) {
                    console.error(
                      `ETH Price for ${timestamp} and ${timestampMinusOneDay} is missing. Please contact developer. Using latest ETH price in the meantime`
                    );
                    ethPx = Number(ethPrices["latest"]);
                  } else ethPx = Number(ethPrices[timestampMinusOneDay]);
                } else ethPx = Number(ethPrices[timestamp]); // price for that day
                const value = Number(ethers.utils.formatEther(tokenvalue)); // value in ETH
                const usdvalue = ethPx * value;
                collections["nfttrader"].usdout.push(usdvalue);
              }
            }

            if (addresses.includes(to.toLowerCase()) && !addresses.includes(from.toLowerCase())) {
              collections["nfttrader"].ethin.push({ asset: tokenname, value: tokenvalue });
              if (tokenname === "ETH" || tokenname === "WETH") {
                let ethPx: number;
                if (!ethPrices[timestamp]) {
                  console.error(`ETH price for ${timestamp} not available. Trying to use previous day or latest`);
                  //handle when timestamp is not in ethPrices yet -> check if date is in future then use latest
                  const splitTimestamp = timestamp.split("/");
                  const timestampMinusOneDay = new Date(
                    `${splitTimestamp[1]}/${Number(splitTimestamp[0]) - 1}'/${splitTimestamp[2]} UTC`
                  ).toLocaleDateString("en-gb", {
                    timeZone: "UTC",
                  });
                  if (!ethPrices[timestampMinusOneDay]) {
                    console.error(
                      `ETH Price for ${timestamp} and ${timestampMinusOneDay} is missing. Please contact developer. Using latest ETH price in the meantime`
                    );
                    ethPx = Number(ethPrices["latest"]);
                  } else ethPx = Number(ethPrices[timestampMinusOneDay]);
                } else ethPx = Number(ethPrices[timestamp]); // price for that day
                const value = Number(ethers.utils.formatEther(tokenvalue)); // value in ETH
                const usdvalue = ethPx * value;
                collections["nfttrader"].usdin.push(usdvalue);
              }
            }
          } else {
            if (gas === undefined) return;
            if (nftname === undefined) return;
            if (nfttokenid === undefined) return;
            if (tokencontract === undefined) return;
            if (from === undefined || to === undefined) return;
            if (!collections[nftname])
              collections[nftname] = {
                collectionName: nftname,
                contractAddress: tokencontract,
                slug: "",
                tokenIds: [],
                tokens: 0,
                ethin: [],
                ethout: [],
                netvalue: { ETH: BigNumber.from(0) },
                valuein: { ETH: BigNumber.from(0) },
                valueout: { ETH: BigNumber.from(0) },
                floor: { looksrare: "0", opensea: "0", lrerror: false, oserror: false, lrcached: false, oscached: false },
                gas: [],
                totalgas: BigNumber.from(0),
                balanceqty: "",
                unrealizedvalue: "",
                usdunrealizedvalue: 0,
                scam: false,
                txnHashes: [],
                usdin: [],
                usdout: [],
                usdgas: [],
                usdtotalgas: 0,
                usdnetvalue: 0,
              };

            const findTokenId = collections[nftname].tokenIds.findIndex((tokenid) => tokenid === nfttokenid);

            if (addresses.includes(from.toLowerCase()) && !addresses.includes(to.toLowerCase())) {
              //splices from tokenarray
              collections[nftname].tokens -= nftqty || 0;
              if (findTokenId === -1) return;
              collections[nftname].tokenIds.splice(findTokenId, 1);
            }

            if (addresses.includes(to.toLowerCase()) && !addresses.includes(from.toLowerCase())) {
              collections[nftname].tokens += nftqty || 0;
              if (findTokenId !== -1) return;
              collections[nftname].tokenIds.push(nfttokenid);
            }
          }
        });
        setStatusStep((prevState) => ({ ...prevState, 7: "step-success" }));
      } catch (err) {
        console.log("Collections Obj Setup", err);
        setStatusStep((prevState) => ({ ...prevState, 7: "step-error" }));
        return;
      }

      //Floor Fetch Promise Setup  + Summing of Gas and ETH Values + sum up NFT Balances
      console.log("setting up promise + summing of gas + calc nft Balances");
      setStatusLog("Setting up promise + summing of gas + calc nft balances");
      setStatusStep((prevState) => ({ ...prevState, 8: "step-warning" }));
      let promiseDelayInc = 1000;
      let hashUsed: { [key: string]: boolean } = {};
      try {
        Object.keys(collections).forEach(async (nftName, index) => {
          const checkScam = nftContractData.findIndex((nft) => nft.address.toLowerCase() === collections[nftName].contractAddress.toLowerCase());
          if (checkScam !== -1) {
            if (nftContractData[checkScam].scam) collections[nftName].scam = true;
          }
          if (scamAdds.includes(collections[nftName].contractAddress)) collections[nftName].scam = true;
          if (scamAdds.includes(collections[nftName].contractAddress) || collections[nftName].scam) return;
          //sum up nft balances
          collections[nftName].balanceqty = collections[nftName].tokens.toString();
          if (nftName !== "nfttrader") {
            //Set up Promise to retrieve Floor from LooksRare
            const LRfloorFetchReq = new Promise(async (resolve) => {
              setTimeout(async () => {
                try {
                  if (collections[nftName].balanceqty === "0")
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", lrerror: false });
                  const floorfetch = await axios.get(
                    "https://api.looksrare.org/api/v1/collections/stats?address=" + collections[nftName].contractAddress
                  );
                  if (floorfetch.data.data.floorPrice)
                    return resolve({
                      nftName,
                      nftContract: collections[nftName].contractAddress,
                      floor: floorfetch.data.data.floorPrice,
                      lrerror: false,
                    });
                  else {
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", lrerror: false });
                  }
                } catch (err: any) {
                  if (!err.response.status || err.response.status !== 404) floorError = true;
                  return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", lrerror: true });
                }
              }, index * promiseDelayInc);
            });
            LRfloorFetchArr.push(LRfloorFetchReq);

            const OSfloorFetchReq = new Promise(async (resolve) => {
              setTimeout(async () => {
                try {
                  if (collections[nftName].balanceqty === "0")
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", oserror: false });

                  //check to see if slug is in nftContractData
                  const checkAdd = nftContractData.findIndex(
                    (nft) => nft.address.toLowerCase() === collections[nftName].contractAddress.toLowerCase()
                  );
                  if (checkAdd === -1 || !nftContractData[checkAdd].slug) {
                    //add doesnt exist or slug doesnt exist
                    const getSlug = await axios.get("https://api.opensea.io/api/v1/asset_contract/" + collections[nftName].contractAddress);
             
                    const slug = getSlug.data.collection.slug;
                    collections[nftName].slug = slug;
                  } else {
                    collections[nftName].slug = nftContractData[checkAdd].slug;
                  }

                  const floorfetch = await axios.get(`https://api.opensea.io/api/v1/collection/${collections[nftName].slug}/stats`);
                  if (floorfetch.data.stats.floor_price)
                    return resolve({
                      nftName,
                      nftContract: collections[nftName].contractAddress,
                      floor: ethers.utils.parseEther(floorfetch.data.stats.floor_price.toString()).toString(),
                      oserror: false,
                    });
                  else {
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", oserror: false }); // in future, can cache olderfloors and read from there?
                  }
                } catch (err: any) {
                  if (!err.response || err.response.status !== 404) {
                    floorError = true;
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", oserror: true });
                  } else {
                    collections[nftName].scam = true;
                    return resolve({ nftName, nftContract: collections[nftName].contractAddress, floor: "0", oserror: false });
                  } //if 404, flag as scam collection !!
                }
              }, index * promiseDelayInc);
            });
            OSfloorFetchArr.push(OSfloorFetchReq);

            let totalGas = BigNumber.from(0);
            let usdtotalGas = 0;
            const hashFilterArr = txnDatabyHashArr.filter((txnByHash) => txnByHash.transactions.filter((tx) => tx.nftname === nftName).length > 0);

            hashFilterArr.forEach((txnByHash) => {
              if (hashUsed[txnByHash.hash]) return;

              collections[nftName].txnHashes.push(txnByHash);

              //Gas Array for all txns relating to the collection and sums up totalGas per collection
              if (txnByHash.gas) {
                collections[nftName].gas.push(txnByHash.gas);
                totalGas = totalGas.add(txnByHash.gas);

                const ethPx = Number(ethPrices[txnByHash.timestamp]); // price for that day
                const value = Number(ethers.utils.formatEther(txnByHash.gas)); // value in ETH
                const usdvalue = ethPx * value;
                collections[nftName].usdgas.push(usdvalue);
                usdtotalGas += usdvalue;
              }
              collections[nftName].totalgas = totalGas;
              collections[nftName].usdtotalgas = usdtotalGas;

              //Pushes ETH/WETH/ERC20 token values into an array for each collection
              const tokenFilterArr = txnByHash.transactions.filter((txn) => txn.assettype === "token");
              if (tokenFilterArr.length === 0) return;
              tokenFilterArr.forEach((tx) => {
                const { tokenname, tokenvalue, from, to, timestamp } = tx;
                if (!tokenname || !tokenvalue || !from || !to) return;

                if (addresses.includes(from.toLowerCase()) && !addresses.includes(to.toLowerCase())) {
                  collections[nftName].ethout.push({ asset: tokenname, value: tokenvalue });
                  if (tokenname === "ETH" || tokenname === "WETH") {
                    let ethPx: number;
                    if (!ethPrices[timestamp]) {
                      console.error(`ETH price for ${timestamp} not available. Trying to use previous day or latest`);
                      //handle when timestamp is not in ethPrices yet -> check if date is in future then use latest
                      const splitTimestamp = timestamp.split("/");
                      const timestampMinusOneDay = new Date(
                        `${splitTimestamp[1]}/${Number(splitTimestamp[0]) - 1}'/${splitTimestamp[2]} UTC`
                      ).toLocaleDateString("en-gb", {
                        timeZone: "UTC",
                      });
                      if (!ethPrices[timestampMinusOneDay]) {
                        console.error(
                          `ETH Price for ${timestamp} and ${timestampMinusOneDay} is missing. Please contact developer. Using latest ETH price in the meantime`
                        );
                        ethPx = Number(ethPrices["latest"]);
                      } else ethPx = Number(ethPrices[timestampMinusOneDay]);
                    } else ethPx = Number(ethPrices[timestamp]); // price for that day
                    const value = Number(ethers.utils.formatEther(tokenvalue)); // value in ETH
                    const usdvalue = ethPx * value;
                    collections[nftName].usdout.push(usdvalue);
                  }
                }

                if (addresses.includes(to.toLowerCase()) && !addresses.includes(from.toLowerCase())) {
                  collections[nftName].ethin.push({ asset: tokenname, value: tokenvalue });
                  if (tokenname === "ETH" || tokenname === "WETH") {
                    let ethPx: number;
                    if (!ethPrices[timestamp]) {
                      console.error(`ETH price for ${timestamp} not available. Trying to use previous day or latest`);
                      //handle when timestamp is not in ethPrices yet -> check if date is in future then use latest
                      const splitTimestamp = timestamp.split("/");
                      const timestampMinusOneDay = new Date(
                        `${splitTimestamp[1]}/${Number(splitTimestamp[0]) - 1}'/${splitTimestamp[2]} UTC`
                      ).toLocaleDateString("en-gb", {
                        timeZone: "UTC",
                      });
                      if (!ethPrices[timestampMinusOneDay]) {
                        console.error(
                          `ETH Price for ${timestamp} and ${timestampMinusOneDay} is missing. Please contact developer. Using latest ETH price in the meantime`
                        );
                        ethPx = Number(ethPrices["latest"]);
                      } else ethPx = Number(ethPrices[timestampMinusOneDay]);
                    } else ethPx = Number(ethPrices[timestamp]); // price for that day
                    const value = Number(ethers.utils.formatEther(tokenvalue)); // value in ETH
                    const usdvalue = ethPx * value;
                    collections[nftName].usdin.push(usdvalue);
                  }
                }
              });
              if (!hashUsed[txnByHash.hash]) hashUsed[txnByHash.hash] = true;
            });
          } else {
            const hashFilterArr = txnDatabyHashArr.filter(
              (txnByHash) => txnByHash.transactions.filter((tx) => tx.assettype === "nfttradertoken").length > 0
            );
            hashFilterArr.forEach((txnByHash) => {
              if (hashUsed[txnByHash.hash]) return;
              collections[nftName].txnHashes.push(txnByHash);
              if (!hashUsed[txnByHash.hash]) hashUsed[txnByHash.hash] = true;
            });
          }

          //sum up value of ETH/ERC20 tokens according to asset type
          let valueOut: { [key: string]: BigNumber } = {};
          let valueIn: { [key: string]: BigNumber } = {};
          collections[nftName].ethout.forEach((tokenout) => {
            const { asset, value } = tokenout;

            if (asset === "WETH" || asset === "ETH") {
              if (!valueOut["ETH"]) valueOut["ETH"] = BigNumber.from(0);
              valueOut["ETH"] = valueOut["ETH"].add(value);
            } else {
              if (!valueOut[asset]) valueOut[asset] = BigNumber.from(0);
              valueOut[asset] = valueOut[asset].add(value);
            }
          });
          collections[nftName].ethin.forEach((tokenin) => {
            const { asset, value } = tokenin;
            if (asset === "WETH" || asset === "ETH") {
              if (!valueIn["ETH"]) valueIn["ETH"] = BigNumber.from(0);
              valueIn["ETH"] = valueIn["ETH"].add(value);
            } else {
              if (!valueIn[asset]) valueIn[asset] = BigNumber.from(0);
              valueIn[asset] = valueIn[asset].add(value);
            }
          });
          collections[nftName].valuein = valueIn;
          collections[nftName].valueout = valueOut;

          //calculates net Value in/Out for each token (ETH / ERC20)
          let valueNet: { [key: string]: BigNumber } = {};

          Object.keys(collections[nftName].valuein).forEach((token) => {
            const value = collections[nftName].valuein[token];
            if (!valueNet[token]) valueNet[token] = BigNumber.from(0);
            valueNet[token] = valueNet[token].add(value);
          });
          Object.keys(collections[nftName].valueout).forEach((token) => {
            const value = collections[nftName].valueout[token];
            if (!valueNet[token]) valueNet[token] = BigNumber.from(0);
            valueNet[token] = valueNet[token].sub(value);
          });
          if (!valueNet["ETH"]) valueNet["ETH"] = BigNumber.from(0);
          valueNet["ETH"] = valueNet["ETH"].sub(collections[nftName].totalgas);

          collections[nftName].netvalue = valueNet;

          //calculates net Value in USD terms for ETH/WETH
          let usdvalueNet = 0;
          collections[nftName].usdin.forEach((usdAmt) => {
            usdvalueNet += usdAmt;
          });
          collections[nftName].usdout.forEach((usdAmt) => {
            usdvalueNet -= usdAmt;
          });
          collections[nftName].usdnetvalue = usdvalueNet - collections[nftName].usdtotalgas;
        });

        const totalValueObj = await getTotalValue(collections);
        setTotalValue(totalValueObj);
        setCollectionsObj(collections);
        setTotalValue(totalValueObj);
        setTxnDataHash(txnDatabyHashArr);
        setTxnDataOutput(txnDatabyHashArr);

        setStatusStep((prevState) => ({ ...prevState, 8: "step-success", modal: false }));
        setFetchingData((prevState) => ({ ...prevState, realized: false }));
      } catch (err) {
        console.log("Floor Fetch Setup + Summing of Value/GAS", err);
        setStatusStep((prevState) => ({ ...prevState, 8: "step-error" }));
        throw new Error("Unable to calculate Realized P&L");
      }

      //Floor Fetch Promise Execute
      console.log("Fetching Floor");
      setStatusLog("Fetching Floors... This may take awhile");
      setStatusStep((prevState) => ({ ...prevState, 9: "step-warning" }));
      try {
        const LRfloorPromise = await Promise.all(LRfloorFetchArr);
        LRfloorPromise.forEach((floorObj) => {
          const { nftName, floor, lrerror } = floorObj;
          if (lrerror) {
            if (fetchedData.collectionsFloor[nftName] && fetchedData.collectionsFloor[nftName].looksrare !== "0")
              collections[nftName].floor.looksrare = fetchedData.collectionsFloor[nftName].looksrare;
            else collections[nftName].floor.looksrare = floor;
            collections[nftName].floor.lrerror = lrerror;
          } else {
            if (!fetchedData.collectionsFloor[nftName])
              fetchedData.collectionsFloor[nftName] = { looksrare: floor, opensea: collections[nftName].floor.opensea };
            else fetchedData.collectionsFloor[nftName].looksrare = floor;
            collections[nftName].floor.looksrare = floor;
            collections[nftName].floor.lrerror = lrerror;
          }
        });
        const OSfloorPromise = await Promise.all(OSfloorFetchArr);
        OSfloorPromise.forEach((floorObj) => {
          const { nftName, floor, oserror } = floorObj;
          if (oserror) {
            if (fetchedData.collectionsFloor[nftName] && fetchedData.collectionsFloor[nftName].opensea !== "0")
              collections[nftName].floor.opensea = fetchedData.collectionsFloor[nftName].opensea;
            else collections[nftName].floor.opensea = floor;
            collections[nftName].floor.oserror = oserror;
          } else {
            if (!fetchedData.collectionsFloor[nftName])
              fetchedData.collectionsFloor[nftName] = { looksrare: collections[nftName].floor.looksrare, opensea: floor };
            else fetchedData.collectionsFloor[nftName].opensea = floor;
            collections[nftName].floor.opensea = floor;
            collections[nftName].floor.oserror = oserror;
          }
        });

        if (floorError) setStatusStep((prevState) => ({ ...prevState, 9: "step-error" }));
        else setStatusStep((prevState) => ({ ...prevState, 9: "step-success" }));
        setFetchedDataState((prevState) => ({ ...prevState, collectionsFloor: fetchedData.collectionsFloor }));
      } catch (err) {
        console.log("Floor Promise Execute", err);
        setStatusStep((prevState) => ({ ...prevState, 9: "step-error" }));
        // return;
      }

      //Estimate Unrealized Value using Floor Price and Balance
      console.log("Calculating Unrealized NFT Value");
      setStatusLog("Calculating Unrealized NFT values");
      setStatusStep((prevState) => ({ ...prevState, 10: "step-warning" }));
      try {
        Object.keys(collections).forEach((nftName) => {
          if (scamAdds.includes(collections[nftName].contractAddress) || collections[nftName].scam) return;
          const { floor, balanceqty } = collections[nftName];
          if (!balanceqty) return;
          const LRunrealizedvalueBN = BigNumber.from(floor.looksrare).mul(BigNumber.from(balanceqty));
          const OSunrealizedvalueBN = BigNumber.from(floor.opensea).mul(BigNumber.from(balanceqty));
          const LRunrealizedvalue = Number(ethers.utils.formatEther(LRunrealizedvalueBN));
          const OSunrealizedvalue = Number(ethers.utils.formatEther(OSunrealizedvalueBN));
          if (floor.opensea !== "0" && floor.looksrare !== "0")
            collections[nftName].unrealizedvalue = Math.min(LRunrealizedvalue, OSunrealizedvalue).toString();
          else if (floor.opensea === "0" && floor.looksrare !== "0") collections[nftName].unrealizedvalue = LRunrealizedvalue.toString();
          else if (floor.opensea !== "0" && floor.looksrare === "0") collections[nftName].unrealizedvalue = OSunrealizedvalue.toString();
          else collections[nftName].unrealizedvalue = "0";

          //calc estimated value in USD terms based on today's ETH price
          const ethPx = Number(ethPrices["latest"]);
          collections[nftName].usdunrealizedvalue = ethPx * Number(collections[nftName].unrealizedvalue);
        });

        setStatusStep((prevState) => ({ ...prevState, 10: "step-success" }));
      } catch (err) {
        console.log("Unrealized Value Calc", err);
        setStatusStep((prevState) => ({ ...prevState, 10: "step-error" }));
        throw new Error("Unable to Calculate NFT Holdings Value");
      }
      return collections;
    } catch (err) {
      console.log("main Collections function", err);
      throw new Error(`Error generating Collections data: ${err}`);
    }
  };

  const txnDataFilter = async () => {
    //WIP -> filter needs to contain collection names/ use collectionsObj.keys
    let filteroption = filterSelect;
    if (!filteroption["nftname"]) filteroption["nftname"] = "";
    // if (value === "") delete filteroption["nftname"]; to handle when dropdown selects none
    filteroption["nftname"] = "CloneX";
    setFilterSelect(filteroption);

    let results = txnDataHash.filter(
      (txnbyHash) => txnbyHash.transactions?.findIndex((tx) => tx.nftname?.toLowerCase() === filteroption["nftname"].toLowerCase()) !== -1
    );
    setTxnDataOutput(results);
  };

  return (
    <div data-theme="maindark" className="bg-base-100 text-base-content w-full font-exo  min-h-screen ">
      {fetchingData.unrealized && (
        <div className="alert alert-info shadow-lg  sticky top-0 z-999">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>{statusLog}</span>
          </div>
        </div>
      )}
      <Header />
      <div className="container mx-auto">
        <div className="py-10  flex flex-col min-h-[80vh] ">
          <div className=" flex flex-col items-center w-full">
            <h1 className="font-bold">Step 1: Enter Your ETH Addresses/ENS </h1>
            <AddressInput {...{ addressInputArr, setAddressInputArr, fetchingData }} />

            <div className="">
              <h1 className="font-bold mt-10">Step 2: Click "Run" Button</h1>
              <button className={` btn-block mb-5 btn btn-success  px-10 ${fetchingData.button ? "loading disabled " : ""}`} onClick={genTxHistory}>
                {fetchingData.button ? "App Running" : "Run"}
              </button>
            </div>
            <div className="border rounded p-5">App Status: {statusLog}</div>
            <StepModal {...{ statusStep, setStatusStep, genTxHistory, statusLog, fetchingData }} />
          </div>
          {showResults && (
            <>
              {fetchingData.realized ? (
                <div className="flex flex-col w-full bg-base-content  h-32  animate-pulse my-10 "></div>
              ) : (
                <>
                  <ResultsNav />
                  <Routes>
                    <Route path="/">
                      <Route index element={<TotalStats {...{ totalValue, tokenDecimal, fetchingData }} />} />
                      <Route path="collections" element={<CollectionStats {...{ collectionsObj, fetchingData }} />} />
                      <Route
                        path="txhash"
                        element={<TransactionByHashPaginate {...{ fetchingData, txnDataOutput, addressesArr, tokenDecimal }} itemsPerPage={20} />}
                      />
                    </Route>
                  </Routes>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
