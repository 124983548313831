import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

import type { collectionEntryType } from "../utils/types";

const CollectionStats = (props: {
  collectionsObj: { [key: string]: collectionEntryType };
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
}) => {
  const { collectionsObj, fetchingData } = props;
  const [collectionsArr, setCollectionsArr] = useState<collectionEntryType[]>([]);
  const [switchCurrency, setSwitchCurrency] = useState<"ETH" | "USD">("USD");
  const [sortCat, setSortCat] = useState<"collection" | "owned" | "realized" | "unrealized" | "total">("total");
  const [sortObj, setSortObj] = useState<{ [key: string]: "up" | "down" | "" }>({
    collection: "down",
    owned: "down",
    realized: "down",
    unrealized: "down",
    total: "up",
  });

  useEffect(() => {
    let collections: collectionEntryType[] = [];
    Object.keys(collectionsObj).forEach((collectionName) => {
      collections.push(collectionsObj[collectionName]);
    });
    setCollectionsArr(
      collections
        .filter((collection) => collection.scam === false)
        .sort((a, b) => Number(b.usdunrealizedvalue) + Number(b.usdnetvalue) - (Number(a.usdunrealizedvalue) + Number(a.usdnetvalue)))
    );
  }, [collectionsObj]);

  const handleSort = (
    category: "collection" | "owned" | "realized" | "unrealized" | "total",
    currency: "ETH" | "USD",
    sortObject: { [key: string]: "up" | "down" | "" }
  ) => {
    switch (category) {
      case "collection":
        sortObject[category] === "up"
          ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => a.collectionName.localeCompare(b.collectionName)))
          : setCollectionsArr((prevState) => [...prevState].sort((a, b) => a.collectionName.localeCompare(b.collectionName)).reverse());
        break;
      case "owned":
        sortObject[category] === "up"
          ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(b.balanceqty) - Number(a.balanceqty)))
          : setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(a.balanceqty) - Number(b.balanceqty)));
        break;
      case "realized":
        if (currency === "USD") {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(b.usdnetvalue) - Number(a.usdnetvalue)))
            : setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(a.usdnetvalue) - Number(b.usdnetvalue)));
        } else {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(b.netvalue["ETH"]) - Number(a.netvalue["ETH"])))
            : setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(a.netvalue["ETH"]) - Number(b.netvalue["ETH"])));
        }
        break;
      case "unrealized":
        if (currency === "USD") {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(b.usdunrealizedvalue) - Number(a.usdunrealizedvalue)))
            : setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(a.usdunrealizedvalue) - Number(b.usdunrealizedvalue)));
        } else {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(b.unrealizedvalue) - Number(a.unrealizedvalue)))
            : setCollectionsArr((prevState) => [...prevState].sort((a, b) => Number(a.unrealizedvalue) - Number(b.unrealizedvalue)));
        }
        break;
      case "total":
        if (currency === "USD") {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) =>
                [...prevState].sort(
                  (a, b) => Number(b.usdunrealizedvalue) + Number(b.usdnetvalue) - (Number(a.usdunrealizedvalue) + Number(a.usdnetvalue))
                )
              )
            : setCollectionsArr((prevState) =>
                [...prevState].sort(
                  (a, b) => Number(a.usdunrealizedvalue) + Number(a.usdnetvalue) - (Number(b.usdunrealizedvalue) + Number(b.usdnetvalue))
                )
              );
        } else {
          sortObject[category] === "up"
            ? setCollectionsArr((prevState) =>
                [...prevState].sort(
                  (a, b) =>
                    Number(ethers.utils.formatEther(b.netvalue["ETH"])) +
                    Number(b.unrealizedvalue) -
                    (Number(ethers.utils.formatEther(a.netvalue["ETH"])) + Number(a.unrealizedvalue))
                )
              )
            : setCollectionsArr((prevState) =>
                [...prevState].sort(
                  (a, b) =>
                    Number(ethers.utils.formatEther(a.netvalue["ETH"])) +
                    Number(a.unrealizedvalue) -
                    (Number(ethers.utils.formatEther(b.netvalue["ETH"])) + Number(b.unrealizedvalue))
                )
              );
        }
        break;
    }
  };

  const handleClickSort = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    category: "collection" | "owned" | "realized" | "unrealized" | "total"
  ) => {
    e.preventDefault();
    setSortCat(category);
    let tempObj = { ...sortObj };
    if (!tempObj[category] || tempObj[category] !== "up") tempObj[category] = "up";
    else tempObj[category] = "down";

    setSortObj(tempObj);

    handleSort(category, switchCurrency, tempObj);
  };

  const toggleCurrency = () => {
    if (switchCurrency === "ETH") {
      setSwitchCurrency("USD");
      handleSort(sortCat, "USD", sortObj);
    } else {
      setSwitchCurrency("ETH");
      handleSort(sortCat, "ETH", sortObj);
    }
  };

  return (
    <div>
      <p> Collections: {collectionsArr.length}</p>

      <div className="flex flex-row gap-2 mt-2">
        <p>Show stats in:</p> <span>USD</span>
        <input
          className="toggle toggle-primary "
          type="checkbox"
          role="switch"
          checked={switchCurrency === "ETH" ? true : false}
          onChange={toggleCurrency}
        />
        <span>ETH</span>
      </div>
      <div className="flex flex-col w-full ">
        {switchCurrency === "USD" && (
          <>
            <p className="text-right">Figures in USD</p>
          </>
        )}
        {switchCurrency === "ETH" && (
          <>
            <p className="text-right">Figures in ETH</p>
          </>
        )}
        <p className="text-sm italic text-right">*click on column headers to sort in ascending/descending order</p>
        <div id="fulltxn-USD" className=" flex  w-full overflow-x-auto ">
          <table className="table table-compact w-full table-zebra  md:tablefixed  text-center">
            <thead>
              <tr>
                <th></th>
                <th className="">
                  <div
                    className="flex flex-row gap-1 items-center justify-center hover:cursor-pointer hover:text-info"
                    onClick={(e) => handleClickSort(e, "collection")}
                  >
                    Collection
                    <div className={`flex flex-col ${sortCat === "collection" ? "" : "hidden"}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th className=" md:w-28 ">
                  <div
                    className="flex flex-row gap-1 items-center justify-center hover:cursor-pointer hover:text-info"
                    onClick={(e) => handleClickSort(e, "owned")}
                  >
                    Owned
                    <div className={`flex flex-col ${sortCat === "owned" ? "" : "hidden"}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th className=" md:w-24  ">
                  <div
                    className="flex flex-row gap-1 items-center justify-center hover:cursor-pointer hover:text-info"
                    onClick={(e) => handleClickSort(e, "unrealized")}
                  >
                    Unrealized Value
                    <div className={`flex flex-col ${sortCat === "unrealized" ? "" : "hidden"}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th className=" md:w-28">
                  <div
                    className="flex flex-row gap-1 items-center justify-center hover:cursor-pointer hover:text-info"
                    onClick={(e) => handleClickSort(e, "realized")}
                  >
                    Realized Profit/Loss
                    <div className={`flex flex-col ${sortCat === "realized" ? "" : "hidden"}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="flex flex-row gap-1 items-center justify-center hover:cursor-pointer hover:text-info"
                    onClick={(e) => handleClickSort(e, "total")}
                  >
                    Total Value
                    <div className={`flex flex-col ${sortCat === "total" ? "" : "hidden"}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {switchCurrency === "USD" && (
                <>
                  {collectionsArr.map((collection, index) => (
                    <tr key={`collectiontable/${index}`} className="border-t border-b border-base-content ">
                      <td>{index + 1}</td>
                      <td className="text-left flex flex-col p-0 m-0">
                        <span>{collection.collectionName}</span>
                        <span>{collection.slug}</span>
                      </td>
                      <td>{collection.balanceqty}</td>
                      <td>
                        <span className={`${fetchingData.unrealized ? "animate-pulse bg-base-content text-base-content" : ""}`}>
                          {Intl.NumberFormat().format(Math.round(collection.usdunrealizedvalue))}
                        </span>
                      </td>
                      <td>{Intl.NumberFormat().format(Math.round(collection.usdnetvalue))}</td>
                      <td> {Intl.NumberFormat().format(Math.round(collection.usdnetvalue + collection.usdunrealizedvalue))} </td>
                    </tr>
                  ))}
                </>
              )}
              {switchCurrency === "ETH" && (
                <>
                  {collectionsArr.map((collection, index) => (
                    <tr key={`collectiontable/${index}`} className="border-t border-b border-base-content ">
                      <td>{index + 1}</td>
                      <td className="text-left flex flex-col p-0 m-0">
                        <span>{collection.collectionName}</span>
                        <span>{collection.slug}</span>
                      </td>
                      <td>{collection.balanceqty}</td>
                      <td>
                        <span className={`${fetchingData.unrealized ? "animate-pulse bg-base-content text-base-content" : ""}`}>
                          {Number(collection.unrealizedvalue).toFixed(2)}
                        </span>
                      </td>
                      <td>{Number(ethers.utils.formatEther(collection.netvalue["ETH"])).toFixed(2)}</td>
                      <td> {(Number(ethers.utils.formatEther(collection.netvalue["ETH"])) + Number(collection.unrealizedvalue)).toFixed(2)} </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CollectionStats;
