import React from "react";

import type { addressInputObj } from "../utils/types";

const AddressInput = (props: {
  addressInputArr: addressInputObj[];
  setAddressInputArr: React.Dispatch<React.SetStateAction<addressInputObj[]>>;
  fetchingData: { unrealized: boolean; realized: boolean; button: boolean };
}) => {
  const { addressInputArr, setAddressInputArr, fetchingData } = props;

  const addAddress = () => {
    setAddressInputArr((prevState) => [...prevState, { input: "", address: "", ensname: "", status: "", error: false, ok: false }]);
  };

  const removeAddress = (index: number) => {
    const list = [...addressInputArr];
    list.splice(index, 1);
    setAddressInputArr(list);
  };

  const handleAddressArrInput = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    const list = [...addressInputArr];
    list[index].input = value.toLowerCase();
    setAddressInputArr(list);
  };
  return (
    <div className="w-full border rounded-xl ">
      {addressInputArr.map((address, i) => (
        <div key={`giveaway/${i}`} className=" flex flex-col items-center w-full">
          <div className="flex flex-col lg:flex-row justify-center align-middle gap-2  w-full">
            <div className="form-control w-full lg:w-3/4 lg:flex-row  lg:items-end ">
              <label className="input-group input-group-vertical lg:input-group-sm  lg:max-w-[60%]">
                <span className="w-[5%] min-w-fit px-1 mt-2">Address {i + 1}</span>
                <input
                  type="text"
                  name="input"
                  value={address.input}
                  placeholder="Input ETH Address or ENS name"
                  onChange={(e) => handleAddressArrInput(e, i)}
                  className="input input-bordered input-sm w-full"
                  disabled={fetchingData.button ? true : false}
                />
              </label>
              {address.ok && <div className="mx-2 text-sm"> Address is ok </div>}
              {address.error && <div className="mx-2 text-sm"> {address.status} </div>}
            </div>

            <div className=" align-middle justify-center flex flex-col min-w-[15%] w-fit ">
              {addressInputArr.length !== 1 && (
                <button className={`btn btn-primary btn-sm ${fetchingData.button ? "btn-disabled" : ""}`} onClick={(e) => removeAddress(i)}>
                  Delete Address {i + 1}
                </button>
              )}
            </div>
          </div>

          <div>
            {addressInputArr.length - 1 === i && (
              <button className={`my-7  btn btn-primary  btn-sm ${fetchingData.button ? "btn-disabled" : ""}`} onClick={addAddress}>
                Add Additional Address
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddressInput;
