import { Link, useLocation } from "react-router-dom";

const ResultsNav = () => {
  const location = useLocation().pathname;

  return (
    <>
      <div id="collectionstab" className=" my-14 w-full">
        <div className="btn btn-ghost no-animation btn-block hover:cursor-default hover:bg-transparent  tracking-wider font-bold text-2xl mb-5 border-t-base-content border-b-base-content hover:border-t-base-content hover:border-b-base-content  ">
          Results
        </div>
        <ul className="menu menu-vertical lg:menu-horizontal font-medium w-full border-b border-base-content ">
          <li
            className={`btn-ghost border-b-2 border-0 hover:border-primary hover:bg-primary -mb-px ${
              location === "/" && "bg-primary text-primary-content"
            }`}
          >
            <Link to="/">Total Stats</Link>
          </li>
          <li
            className={`btn-ghost  border-b-2 border-0 hover:border-primary hover:bg-primary  -mb-px ${
              location === "/collections" && "bg-primary text-primary-content"
            }`}
          >
            <Link to="collections">Stats by Collection</Link>
          </li>
          <li
            className={`btn-ghost border-b-2 border-0 hover:border-primary hover:bg-primary -mb-px ${
              location === "/txhash" && "bg-primary text-primary-content"
            }`}
          >
            <Link to="txhash">All Transactions by Hash</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ResultsNav;
